.ourLocationsDiv {
    background-color: #f39c12;
    display: inline-block;
    width: 100%;
    padding-bottom: 16px;
}

.ourLocationsDiv h3{
    color: #fff;
    margin-top: 20px;
}

.mapMarker {
    display: inline-block;
    padding: 6px;
    width:auto;
    height:auto;
    min-width: 30px;
    background-color:#fff;
    color:#000;
    text-align: center;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 500;
    border-style: solid;
    border-color: #f39c12;
    border-width: 5px;
}