.card-inner-img-card {
    background: url(../../../img/payment/card.svg) no-repeat center;
    background-size: auto 65%;
    width: 100%;
    height: 140px;
}

.card-inner-img-vipps {
    background: url(../../../img/payment/vipps-logo.svg) no-repeat center;
    background-size: 80% auto;
    width: 100%;
    height: 140px;
}
.card-inner-img-invoice {
    background: url(../../../img/payment/invoice.svg) no-repeat center;
    background-size: auto 65%;
    width: 100%;
    height: 140px;
}

.card-footer p{
    font-size: 20px !important;
    margin: 4px;
}

.card-selected {
    transition-duration: .2s;
    -webkit-box-shadow: 0px 0px 0px 5px var(--f7-theme-color) !important;
    -moz-box-shadow: 0px 0px 0px 5px var(--f7-theme-color) !important;
    box-shadow: 0px 0px 0px 5px var(--f7-theme-color) !important;
}

.card-selected .card-footer {
    color:#fff;
    background-color: var(--f7-theme-color) !important;
}