.swiper-container {
    height: 360px;
}

@media only screen and (max-width:480px) {
    .swiper-container {
        height: 460px;
    }
}

@media only screen and (max-width:360px) {
    .swiper-container {
        height: 500px;
    }
}

.slide-1 {
    background-color: #ecf0f1;
}
.slide-2 {
    background-color: #60a3bc;
    color: #ffffff;
}
.slide-3 {
    background-color: #2980b9;
    color: #ffffff;
}