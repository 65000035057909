.col-img {
    height: 380px;
    background-size: 90% auto;
    background-repeat: no-repeat;
    background-position: center;
  }
  
  @media screen and (max-width: 767px){
    .col-img {
      display:none !important;
      /*background-size: auto 90%;*/
    }
  }
  
  .opt-img {
    min-height: 130px;
    width: 100%;
    background-size: auto 50%;
    background-repeat: no-repeat;
    background-position: center 10px;
  }
  
  .opt-img p {
    color: --f7-theme-color;
    text-align: center;
    font-size: 18px;
    padding: 0 0;
    font-weight: 500;
    vertical-align: text-bottom;
    margin: 0;
    margin-top: 90px;
    line-height: 10px;
  }
  .opt-img span {
    display: block;
    color: #666;
    text-align: center;
    font-size: 15px;
    padding: 4px 30px;
    font-weight: 500;
    /*vertical-align: text-bottom;*/
    margin: 0;
  }
  
  .opt-img.active {
    background-color: #333;
  }
  .opt-img.active p {
    color: #fff;
  }
  .opt-img.active span {
     color: #c5c5c5; 
  }
  
  .opt-img.active:hover {
    background-color: #333;
  }
  
  .opt-img:hover {
    background-color: rgba(0,0,0,.3);
    transition-duration: .2s;
  }