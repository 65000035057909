.NavbarIconView {
    display: none;
    width: 100px;
}

@media only screen and (min-width: 768px) {
    .navbar .right .button {
        margin-left:18px;
        margin-right:18px;
    }
}