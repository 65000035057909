:root {
    --f7-font-family: "San Francisco", "Helvetica Neue", "Roboto", Helvetica, Arial, sans-serif;
    --f7-theme-color: #333;
    --f7-theme-color-rgb: 51, 51, 51;
    --f7-theme-color-shade: #2c3e50;
    --f7-theme-color-tint: #2c3e50;
    --f7-safe-area-left: 0px;
    --f7-safe-area-right: 0px;
    --f7-safe-area-top: 0px;
    --f7-safe-area-bottom: 0px;
    --f7-safe-area-outer-left: 0px;
    --f7-safe-area-outer-right: 0px;
    --f7-device-pixel-ratio: 1;
  }
  
  /* Invert navigation bars to fill style */
  :root,
  :root.theme-dark,
  :root .theme-dark {
    --f7-bars-bg-color: var(--f7-theme-color);
    --f7-bars-text-color: #fff;
    --f7-bars-link-color: #fff;
    --f7-navbar-subtitle-text-color: rgba(255,255,255,0.85);
    --f7-bars-border-color: transparent;
    --f7-tabbar-link-active-color: #fff;
    --f7-tabbar-link-inactive-color: rgba(255,255,255,0.54);
    --f7-searchbar-input-bg-color: #fff;
    --f7-sheet-border-color: transparent;
    --f7-tabbar-link-active-border-color: #fff;
  }
  .navbar,
  .toolbar,
  .subnavbar,
  .calendar-header,
  .calendar-footer {
    --f7-touch-ripple-color: var(--f7-touch-ripple-white);
    --f7-link-highlight-color: var(--f7-link-highlight-white);
    --f7-button-text-color: #fff;
    --f7-button-pressed-bg-color: rgba(255,255,255,0.1);
  }

  .page-content {
    background-color:rgb(247, 247, 248);
  }

  ol {
    counter-reset:li; /* Initiate a counter */
    margin-left:0; /* Remove the default left margin */
    padding-left:0; /* Remove the default left padding */
  }
  ol > li {
    position:relative; /* Create a positioning context */
    margin:0 0 6px 2em; /* Give each list item a left margin to make room for the numbers */
    padding:4px 8px; /* Add some spacing around the content */
    list-style:none; /* Disable the normal item numbering */
    border-top:2px solid var(--f7-theme-color);
    background:#fff;
  }
  ol > li:before {
    content:counter(li); /* Use the counter as content */
    counter-increment:li; /* Increment the counter by 1 */
    /* Position and style the number */
    position:absolute;
    top:-2px;
    left:-2em;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;
    box-sizing:border-box;
    width:2em;
    margin-right:8px;
    padding:4px;
    border-top:2px solid var(--f7-theme-color);
    color:#fff;
    background: var(--f7-theme-color);
    font-weight:bold;
    font-family:"Helvetica Neue", Arial, sans-serif;
    text-align:center;
  }
  li ol,
  li ul {margin-top:6px;}
  ol ol li:last-child {margin-bottom:0;}

  ol li a {
    text-decoration:underline;
  }

  h2 {
    color: var(--f7-theme-color);
    font-size: 32px;
    display: flex;
    padding: 0 0;
    font-weight: 500;
  }

  h3 {
    color: var(--f7-theme-color);
    text-align: center;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 0;
    font-weight: 500;
  }

  h4 {
    position: relative;
    overflow: hidden;
    margin: 0;
    font-size: 18px;
    text-transform: var(--f7-block-title-medium-text-transform);
    color: var(--f7-block-title-medium-text-color);
    font-weight: var(--f7-block-title-medium-font-weight);
    line-height: var(--f7-block-title-medium-line-height);
  }

  .logo-pl {
    background: url(../img/logo/pl.png) no-repeat center;
    background-size: cover;
    width: 36px;
    height: 42px;
    margin-left: 12px;
  }


.package-home-card {
  cursor: pointer;
}

.package-home-card .card-header {
  height: 240px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  color: #fff;
}

.package-home-card .card-content h2 {
  margin: 0;
}

.calendar-modal {
  z-index: 100000
}

.cardMapPreview {
  height: 140px;
  background-size: 100% auto;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #ccc;
  color: #fff;
}

.cardErrorShaddow {
  transition-duration: .2s;
  -webkit-box-shadow: 0px 0px 0px 2px var(--f7-color-red) !important;
  -moz-box-shadow: 0px 0px 0px 2px var(--f7-color-red) !important;
  box-shadow: 0px 0px 0px 2px var(--f7-color-red) !important;
}

.cardErrorShaddow:hover {
  transition-duration: .2s;
  -webkit-box-shadow: 0px 0px 0px 2px var(--f7-color-red) !important;
  -moz-box-shadow: 0px 0px 0px 2px var(--f7-color-red) !important;
  box-shadow: 0px 0px 0px 2px var(--f7-color-red) !important;
}

.groupSeparator{
    font-size:16px;
    color:#999;
    width:80%;
    margin:20px auto;
    overflow:hidden;
    text-align:center;
    line-height:1.2em;
}

.groupSeparator:before, .groupSeparator:after{
    content:"";
    vertical-align:top;
    display:inline-block;
    width:50%;
    height:0.65em;
    border-bottom:1px solid #ccc;
    margin:0 2% 0 -55%;
}
.groupSeparator:after{
    margin:0 -55% 0 2%;
}
.searchbar input[type="text"],
.searchbar input[type="search"] {
  padding-left: 40px;
}



@media only screen and (min-width: 768px) {
  .hideFullScreen{
    display: none !important;
  }
}

@media only screen and (max-width: 767px) {
  .hideMobileScreen {
    display: none !important;
  }
}